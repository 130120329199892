<script src="../../../../../Users/ICC 113/Downloads/_worker.js"></script>
<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />
      </b-col>


      <!-- Content Right -->
      <b-col class="content-header-right text-md-right" md="3" cols="12">
        <b-button
            @click="showFormModal(null)"
            class="px-3 text-capitalize"
            variant="primary"
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" />
          ایجاد اقامتگاه
        </b-button>
      </b-col>
    </b-row>
    <div v-if="residences">
      <div class="d-flex justify-content-between align-items-center">
        <per-page v-model="perPage" class="w-50" />
      </div>
      <b-row>
        <!--        <b-col sm="6" md="3" lg="2">
                  <per-page v-model="perPage" class="w-100" />
                </b-col>-->
        <b-col sm="12" md="7" lg="8">
          <search-services
              @searchBase="searchBase"
              :selectedFilter="selectedFilter"
              :submitLoading="searchBasesubmitLoading"
              :isSearchResult="isSearchResult"
              ref="ownersSearchComponent"
              class="w-100"
              v-model="searchInput"
          />
        </b-col>
      </b-row>
      <!-- <search
        ref="residencesSearchComponent"
        class="w-50"
        v-model="searchInput"
      /> -->

      <!--      <b-row class="match-height">
              <b-col
                  sm="12"
                  md="4"
                  lg="3"
                  v-for="(residence, index) in residences" :key="residence.id"
              >
                <b-card
                >
                  <h5>
                    <a target="_blank"  :href="'https://roomtoor.com/space/' + residence.category.slug+'-'+ residence.code">
                      {{ residence.name }}
                    </a>
                  </h5>
                  <b-card-text>
                      <div class="d-flex">
                        <div>کد : </div>
                        <div>
                          <a target="_blank"  :href="'https://roomtoor.com/space/' + residence.category.slug+'-'+ residence.code">
                            {{ residence.code }}
                          </a>
                        </div>
                      </div>
                    <div class="d-flex">
                      <div class="d-flex">
                        <div>استان : </div>
                        <div>
                          {{ residence.state.name }}
                        </div>
                      </div>
                      <div class="d-flex ml-2">
                        <div>شهر : </div>
                        <div>
                          {{ residence.city.name }}
                        </div>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div>میزبان : </div>
                      <div>
                        {{ residence.host.full_name }}
                      </div>
                    </div>
                    <div class="d-flex">
                      <div>واحد مشابه : </div>
                      <div>
                        {{ residence.similar_unit }}
                      </div>
                    </div>
                    <div class="d-flex">
                      <div>تاریخ ایجاد : </div>
                      <div>
                        {{
                          new Date(residence.created_at).toLocaleString("fa-IR", {
                            timeZone: "Iran",
                          })
                        }}
                      </div>
                    </div>
                    <div class="d-flex">
                      <div>تاریخ ویرایش : </div>
                      <div>
                        {{
                          new Date(residence.updated_at).toLocaleString("fa-IR", {
                            timeZone: "Iran",
                          })
                        }}
                      </div>
                    </div>
                  </b-card-text>
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                  >
                    Go Somewhere
                  </b-button>
                </b-card>
              </b-col>


            </b-row>-->

      <b-table-simple responsive>
        <b-thead>
          <b-tr>
            <b-th>کد</b-th>
            <b-th>ترتیب</b-th>
            <b-th>استان</b-th>
            <b-th>شهر</b-th>
            <b-th>عنوان</b-th>
            <b-th>میزبان</b-th>
            <b-th>واحد مشابه</b-th>
            <b-th>تاریخ ایجاد</b-th>
            <b-th>تاریخ آپدیت</b-th>
            <b-th>نمایش</b-th>
            <b-th>وضعیت</b-th>
            <b-th>اخرین روز تقویم</b-th>
            <b-th>عملیات</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(residence, index) in residences" :key="residence.id">
            <b-th>
              <a target="_blank"  :href="'https://roomtoor.com/space/' + residence.category.slug+'-'+ residence.code">
                {{ residence.code }}
              </a>
            </b-th>
            <b-th>
              <span v-if="residence.show_order_number">
                {{ residence.show_order_number}}
              </span>
              <span v-else>
                ----
              </span>
            </b-th>
            <b-th>
              <div>
                <span>
                   {{ residence.state.name }}
                </span>

              </div>
            </b-th>
            <b-th>{{ residence.city.name }}</b-th>
            <b-td>
              <a target="_blank"  :href="'https://roomtoor.com/space/' + residence.category.slug+'-'+ residence.code">
                {{ residence.name }}
              </a>
              <br/>
              <b-badge variant="info">
                {{ residence.category.name}}
              </b-badge>
            </b-td>

            <b-td>
              <a :href="`https://roomtoor.com/user/host/${residence.host.token}`" target="_blank">
                <span class="pl-1">   {{ residence.host.full_name }}</span>
              </a>
              <br/>
              <a href="tel:071-91010100">
                <b-badge variant="primary" v-if="residence.host.mobile">
                  {{   residence.host.mobile.replace("+98","0") }}
                </b-badge>
              </a>
            </b-td>

            <b-td>{{ residence.similar_unit }}</b-td>
            <b-td>
              {{
                new Date(residence.created_at).toLocaleString("fa-IR", {
                  timeZone: "Iran",
                })
              }}
            </b-td>
            <b-td>
              {{
                new Date(residence.updated_at).toLocaleString("fa-IR", {
                  timeZone: "Iran",
                })
              }}
            </b-td>
            <b-td>
              <b-col
                  class="d-flex align-items-center pl-0">
                  <span class="font-weight-bold" v-if="residence.show_index_page === true" style="color: #00c700">
                    فعال
                  </span>
                <span v-else>
                  غیرفعال
                </span>
              </b-col>
            </b-td>
            <b-td>
              <b-col
                  class="d-flex align-items-center pl-0"
                  :class="
                  residence.status != 'active' ? 'text-danger' : 'text-success'
                "
              >
                {{ residence.status == "active" ? "" : "غیر" }}فعال
              </b-col>
            </b-td
            >
            <b-td>
              <div v-if="isNeedToUpdateCalendare(residence.calendars_last_date.date)">
                <b-badge  variant="danger">
                  {{  new Date(residence.calendars_last_date.date).toLocaleDateString("fa-IR", {
                  timeZone: "Iran",
                })}}
                </b-badge>
                <!-- <b-badge>
                {{ isNeedToUpdateCalendare(residence.calendars_last_date.date)?'بروزرسانی تقویم':'' }}
              </b-badge> -->
              </div>
              <div v-else>
                {{

                  new Date(residence.calendars_last_date.date).toLocaleDateString("fa-IR", {
                    timeZone: "Iran",
                  })
                }}</div></b-td>
            <b-td>
              <b-col class="d-flex align-items-center">
                <router-link
                    v-if="residence.checkItems[0] && residence.checkItems[0].serviceable_type==='App\\Models\\Residence'"
                    :to="{ name: 'checkItems', params: {type:'residence', id: residence.id,name:residence.name } }"
                    variant="flat-primary"
                    v-b-tooltip.hover.top="residence.checkItems[0].description"
                    class="font-weight-bolder btn"
                >
                  <AlertTriangleIcon style="color: #00b782" v-if="residence.checkItems[0].status==='complete'" />
                  <AlertTriangleIcon v-else style="color: #fcb200" />
                </router-link>
                <router-link
                    v-else
                    :to="{ name: 'checkItems', params: {type:'residence', id: residence.id,name:residence.name } }"
                    variant="flat-primary"
                    v-b-tooltip.hover.top="'مشکلی ثبت نشده'"
                    class="font-weight-bolder btn"
                >
                  <AlertTriangleIcon />

                </router-link>
                <router-link
                    :to="{ name: 'specificTypeComments', params: {type:'residence', id: residence.id } }"
                    variant="flat-secondary"
                    class="font-weight-bolder btn"
                >
                  <BIconChatRightTextFill />
                </router-link>
                <router-link
                    :to="{ name: 'calender', params: { id: residence.id } }"
                    variant="flat-secondary"
                    class="font-weight-bolder btn"
                >
                  <BIconCalendar />
                </router-link>
                <!--                <b-button
                                    @click="showConfirmModal(residence)"
                                    variant="flat-danger"
                                    class="font-weight-bolder"
                                >
                                  <feather-icon icon="Trash2Icon" size="20" />
                                </b-button>-->
                <b-button
                    @click="showFormModal(residence, index)"
                    variant="flat-secondary"
                    class="font-weight-bolder"
                >
                  <feather-icon icon="Edit2Icon" size="20" />
                </b-button>



              </b-col
              ></b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <pagination
          ref="residencesPaginationComponent"
          v-model="page"
          :paginationData="paginationData"
      />
    </div>
    <b-sidebar
        width="98%"
        v-model="formModal"
        right
        backdrop
        shadow
        bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
                style="background-color: rgba(75, 75, 75, 0.12)"
                @click="formModal = false"
                variant="flat-dark"
                class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{ editMode ? "ویرایش اقامتگاه" : "ایجاد اقامتگاه" }}
            </h3>
          </div>
        </div>
      </template>
      <ResidenceWizard
          @deleteImage="deleteImage"
          ref="residenceInput"
          @getResidences="getResidences"
          :provinces="provinces"
      ></ResidenceWizard>

      <!-- <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
            class="text-capitalize mr-2"
            @click="formModal = false"
            variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="submitForm()">
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template> -->
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BIconCalendar,
  BIconChatRightTextFill,
  BBadge,
  VBTooltip
} from "bootstrap-vue";
import debounce from 'lodash/debounce';
import Ripple from 'vue-ripple-directive'
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";
import ResidenceWizard from "@/components/ResidenceWizard.vue";
import { mapMutations, mapState, mapActions } from "vuex";
import { forEach } from "postcss-rtl/lib/affected-props";
import SearchServices from "@/components/SearchServices.vue";
import AlertTriangleIcon from "vue-feather-icons/icons/AlertTriangleIcon";

export default {
  components: {
    AlertTriangleIcon,
    SearchServices,
    BBadge,
    ResidenceWizard,
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BIconCalendar,
    BIconChatRightTextFill
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isSearchResult:false,
      searchBasesubmitLoading:false,
      selectedFilter:[],
      Dates: [],
      residenceIndex: 0,
      spaces:null,
      itemArray: [
        {
          Item: "ACC",
          Price: 10,
          hasPrice: true,
        },
        {
          Item: "BCC",
          Price: "",
          hasPrice: false,
        },
        {
          Item: "CCC",
          Price: "",
          hasPrice: false,
        },
        {
          Item: "DCC",
          Price: "",
          hasPrice: false,
        },
        {
          Item: "ECC",
          Price: "",
          hasPrice: false,
        },
      ],
      provinces: [],

      perPage: 30,
      page: 1,
      buildings: [],
      residences: null,
      defaultAvatar: require("@/assets/images/avatars/default.png"),
      cancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,
      tableHeadings: [
        {
          title: "شهر",
          slug: "city",
          sortable: false,
        },
        {
          title: "ساختمان",
          slug: "building",
          sortable: true,
        },
        {
          title: "نام",
          slug: "name",
          sortable: true,
        },
        {
          title: "میزبان",
          slug: "owner",
          sortable: false,
        },
        {
          title: "تعداد",
          slug: "count",
          sortable: true,
        },
        {
          title: "تاریخ ایجاد",
          slug: "created_at",
          sortable: true,
        },
        {
          title: "actions",
          sortable: false,
        },
      ],

      OpenIndicator: {
        render: (createElement) =>
            createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      companies: [],
      buildingsCancelToken: undefined,
      residenceInput: {
        id: "",
        building_id: "",
        name: "",
        description: "",
        body: "",
        count: "",
        bedrooms: "",
      },
      editMode: false,
      formModal: false,
      submitLoading: false,
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    this.getResidences();
    this.getResidencesPrerequisite("autoimmunePackages", "autoimmune_packages");
    this.getResidencesPrerequisite("regulations", "regulations");
    this.getResidencesPrerequisite("rules", "rules");
    this.getResidencesPrerequisite("packages", "packages");
    this.getResidencesPrerequisite("categories", "categories");
    this.getResidencesPrerequisite("health_items", "health_items");
    this.getProvinces();
    this.getUrlParams();
  },
  created() {
    // Create a debounced version of the search handler
    this.debouncedGetReservations = debounce(this.getResidences, 500);
  },
  watch: {
    /*    searchInput(value) {
          this.page = 1;
          this.getResidences();
        },*/
    perPage(value) {
      this.page = 1;
      this.getResidences();
    },
    page(value) {
      this.getResidences();
    },
    searchInput(value) {
      this.page = 1; // Reset the page to the beginning
      if (value.trim().length) { // Ensure there is actual input to search for
        this.debouncedGetReservations();
      }
    }
    /*    searchInput() {
          if (this.searchInput) {
            this.getResidences();
          }
        },
        selectedFilter: {
          deep: true,
          handler() {
            if (this.selectedFilter.length && this.searchInput) {
              this.getResidences();
            }
          }
        }*/
  },
  computed: {
    ...mapState([
      "regulations_state",
      "autoimmune_packages_state",
      "rules_state",
      "packages_state",
      "categories_state",
      "health_items_state",
    ]),

  },

  methods: {
    isNeedToUpdateCalendare(lastDate){
      var today=this.$moment()
      var last=this.$moment(new Date(lastDate))
      var diff= last.diff(today,'days')
      if(diff<=62){
        return true
      }else{
        return false
      }

    },
    getUrlParams() {
      if ("filter[name]" in this.$route.query) {
        this.searchInput = this.$route.query[`filter[name]`];
        this.selectedFilter.push("name");
      }
      if ("filter[code]" in this.$route.query) {
        this.searchInput = this.$route.query[`filter[code]`];
        this.selectedFilter.push("code");
      }
      if ("filter[host]" in this.$route.query) {
        this.searchInput = this.$route.query[`filter[host]`];
        this.selectedFilter.push("host");
      }
      if ("filter[city]" in this.$route.query) {
        this.searchInput = this.$route.query[`filter[city]`];
        this.selectedFilter.push("city");
      }
      if ("per_page" in this.$route.query) {
        this.perPage = Number(this.$route.query[`per_page`]);
        this.$refs.userPerPageComponent.perPage = this.perPage;
      }
      if ("page" in this.$route.query) {
        this.page = this.$route.query[`page`];
      }

    },
    searchBase(data) {
      console.log("data", data);
      this.selectedFilter = data.selectedFilter;
      this.searchInput = data.searchInput;
      this.isSearchResult = this.searchInput !== "" && data.type !== "delete";
      this.searchBasesubmitLoading = true;
      this.getResidences();
    },

    /*    searchBase(data) {
          console.log("data", data);

          // Only update searchInput and selectedFilter if needed
          if (data.selectedFilter.length > 0) {
            const filterCriteria = ['name', 'code', 'host', 'city'];
            const isFilterSelected = data.selectedFilter.some(filter => filterCriteria.includes(filter));
            if (isFilterSelected) {
              this.searchInput = data.searchInput;
            }
            this.selectedFilter = data.selectedFilter;
          }

          this.searchBasesubmitLoading = true;

          // Determine if a search result should be shown
          this.isSearchResult = !!this.searchInput;

          if (data.type === "delete") {
            this.isSearchResult = false;
          }

          // Trigger the search
          this.getResidences();
        },*/
    /*    searchBase(data) {
          console.log("data", data);
          this.selectedFilter = [];
          if (data.selectedFilter.length > 0) {
            data.selectedFilter.forEach((item) => {
              if (item == "name") {
                this.searchInput = data.searchInput;
              } else if (item == "code") {
                this.searchInput = data.searchInput;
              } else if (item == "host") {
                this.searchInput = data.searchInput;
              }
              else if (item == "city") {
                this.searchInput = data.searchInput;
              }
            });
            this.selectedFilter = data.selectedFilter;
          }
          this.searchBasesubmitLoading = true;
          if (this.searchInput) {
            this.isSearchResult = true;
          }
          if (data.type == "delete") {
            this.isSearchResult = false;
          }
          this.getResidences();
        },*/


    deleteImage(image) {
      let residenceIndex = 0;
      console.log("this.residences.images", this.residences);
      this.residences[this.residenceIndex].images = Object.values(
          this.residences[this.residenceIndex].images
      ).filter((residenceItem, index) => {
        if (residenceItem.id == image.id) {
          residenceIndex = index;
        }
        return residenceItem.id !== image.id;
      });
    },
    disableUnderCheckbox(data) {
      this.packages[data.index]["disable"] = data.show;
      console.log(this.packages);

      this.$set(this.packages[data.index], "disable", data.show);
      //  this.packages[data.index] = Object.assign({}, this.packages[data.index], this.packages[data.index])
    },
    getProvinces(search, loading) {
      if (typeof this.provincesCancelToken != typeof undefined) {
        this.provincesCancelToken.cancel(
            "Operation canceled due to new request."
        );
      }
      //Save the cancel token for the current request
      this.provincesCancelToken = this.$http.CancelToken.source();

      let data = {
        paginate: false,
        //  this.searchInput,
        // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "/admin/states",
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.provinces = data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getResidencesPrerequisite(stateName, url) {
      // if (typeof this.cancelToken != typeof undefined) {
      //   this.cancelToken.cancel("Operation canceled due to new request.");
      // }
      //Save the cancel token for the current request
      // this.cancelToken = this.$http.CancelToken.source();

      let data = {
        // search: this.searchInput,
        // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: `/admin/residences/${url}`,
        // params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this[stateName] = data.data;
            this[stateName].forEach((element) => {
              element["disable"] = false;
            });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getResidences();
    },

    async getResidences() {
      this.searchBasesubmitLoading = true;
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        // search: this.searchInput,
        "filter[name]": this.selectedFilter.includes("name")
            ? this.searchInput
            : "",
        "filter[code]": this.selectedFilter.includes("code")
            ? this.searchInput
            : "",
        "filter[host]": this.selectedFilter.includes("host")
            ? this.searchInput
            : "",
        "filter[city]": this.selectedFilter.includes("city")
            ? this.searchInput
            : "",
        per_page: this.perPage,
        page: this.page,
        //"filter[code]": this.searchInput,

        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "/admin/new_residences",
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.residences = data.data;
            this.spaces = data;
            this.paginationData = data.meta;
            this.searchBasesubmitLoading = false;
          })
          .catch((err) => {
            //console.log(err);
            this.searchBasesubmitLoading = false;
          });
      this.residences = [];
      this.formModal = false;
    },
    showConfirmModal(residence) {
      this.$bvModal
          .msgBoxConfirm(
              `آیا از حذف اقامتگاه مطمئن هستید ؟  : ${residence.name}.`,
              {
                title: this.$t("Please Confirm"),
                size: "sm",
                okVariant: "primary",
                okTitle: this.$t("Yes"),
                cancelTitle: this.$t("No"),
                cancelVariant: "outline-secondary",
                hideHeaderClose: false,
                centered: true,
              }
          )
          .then(async (confirmed) => {
            if (confirmed) {
              let residenceIndex = 0;
              this.residences = await this.residences.filter(
                  (residenceItem, index) => {
                    if (residenceItem.id == residence.id) {
                      residenceIndex = index;
                    }
                    return residenceItem.id !== residence.id;
                  }
              );

              const requestData = {
                method: "delete",
                url: `admin/residences/${residence.id}`,
              };
              this.$http(requestData)
                  .then((response) => {
                    this.$bvToast.toast(
                        `اقامتگاه: ${residence.name}  با موفقیت حذف شد.`,
                        {
                          title: this.$t("Done"),
                          variant: "success",
                          solid: true,
                        }
                    );
                  })
                  .catch((err) => {
                    let message = this.$t("Unable to delete!");
                    if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    )
                      message = err.response.data.message;
                    this.$bvToast.toast(message, {
                      title: this.$t("Error"),
                      variant: "danger",
                      solid: true,
                    });
                    this.residences.splice(residenceIndex, 0, residence);
                  });
            }
          });
    },
    formatePrices(prices, type) {
      if (prices.name && prices.name == "test") {
        return prices;
      }
      if (type == "prices") {
        var formatedPrices = prices;
        if (prices.normal_dayes)
          formatedPrices.normal_dayes = this.formatnumber(prices.normal_dayes);
        if (prices.weekend_days)
          formatedPrices.weekend_days = this.formatnumber(prices.weekend_days);
        if (prices.peack_days)
          formatedPrices.peack_days = this.formatnumber(prices.peack_days);
        if (prices.extra_guest)
          formatedPrices.extra_guest = this.formatnumber(prices.extra_guest);
        if (prices.pricePerBaby.price !== null) {
          formatedPrices.pricePerBaby.price = this.formatnumber(
              prices.pricePerBaby.price
          );
        }
        if (prices.pricePerAnimal.price !== null) {
          formatedPrices.pricePerAnimal.price = this.formatnumber(
              prices.pricePerAnimal.price
          );
        }
        return formatedPrices;
      } else {
        var formatedSpecial_services = prices;
        if (prices && prices[0] !== null) {
          Object.keys(prices).forEach((element) => {
            formatedSpecial_services[element].price = this.formatnumber(
                prices[element].price
            );
          });

          return formatedSpecial_services;
        }
      }
    },
    formatnumber(newValue) {
      console.log("newValue", newValue);
      const result = newValue
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return result;
    },
    //     monthsBetweenDates(startDate, endDate) {
    //       const now = startDate,
    //         dates = [];

    //       while (now.isSameOrBefore(endDate)) {
    //         // console.log(now.format("dddd"))
    //         if (
    //           now.format("dddd") === "پنج‌شنبه" ||
    //           now.format("dddd") === "چهارشنبه"
    //         ) {

    //           dates.push({ isWeakend: true, date: now.locale('en').format("YYYY/MM/DD"),

    //           books_count:0,
    // disable_count:0,
    // is_custom_price:false,
    // price:

    //           });
    //         } else {
    //           dates.push({ date: now.locale('en').format("YYYY/MM/DD") });
    //         }

    //         now.add(1, "days");
    //       }
    //       return dates;
    //     },
    getDatesFromStartTo6Month() {
      // console.log(this.$moment)
      const endofMonth1 = this.$moment(this.date);
      const toDate1 = this.$moment(endofMonth).endOf("month");
      console.log(endofMonth1, toDate1);
      // console.log("monthsBetweenDates",this.monthsBetweenDates(endofMonth1,toDate1))
      // console.log("date",this.$options.filters.moment(this.date,"jD jMMMM,jYYYY HH:mm").getDay())
      // console.log(this.$moment(this.date).endOf('month').format('YYYY/MM/D'))
      const endofMonth = this.$moment(this.date).endOf("month");
      const toDate = this.$moment(endofMonth).add(6, "months").endOf("month");

      // console.log("toDate",toDate.format('YYYY/MM/D'))
      // console.log("monthsBetweenDates",this.monthsBetweenDates(endofMonth,toDate))
      var x = this.monthsBetweenDates(endofMonth1, toDate1);

      x.splice(-1);
      var y = this.monthsBetweenDates(endofMonth, toDate);
      // this.date.moment("jD jMMMM,jYYYY HH:mm").getDay()
      this.Dates = [...x, ...y];

      console.log(this.Dates);
      return this.Dates;
    },
    showFormModal(residence, index) {
      this.$refs.residenceInput.specialServices = 0;

      console.log("residence", residence);
      if (residence) {
        this.$refs.residenceInput.$refs.formWizard.activateAll()
        this.$refs.residenceInput.residenceInput = {
          id: residence.id,
          name: residence.name,
          slug: residence.slug,
          host_id: residence.host.id,
          category_id: residence.category.id,
          similar_unit: residence.similar_unit,
          size_foundation: residence.size_foundation,
          size_land: residence.size_land,
          floor: residence.floor,
          bathroom: residence.bathroom,
          delivery_time: residence.delivery_time,
          delivery_time_to: residence.delivery_time_to,
          discharge_time: residence.discharge_time,
          discharge_time_to: residence.discharge_time_to,
          residence_area: residence.residence_area,
          rental_method: residence.rental_method,
          description: residence.description,
          covid: residence.covid,
          blog_url: residence.blog_url,
          rooms: residence.rooms,
          rules: residence.rules,
          // regulations: residence.regulations,
          state_id: residence.state.id,
          city_id: residence.city.id,
          address: residence.address,
          autoimmune_packages: residence.autoimmune_packages,
          health_items: residence.health_items,
          // packages: residence.packages,

          prices: this.formatePrices(residence.prices, "prices"),
          // special_services: this.formatePrices(
          //   residence.special_services,
          //   "special_services"
          // ),
          special_services: residence.special_services,

          lat: residence.lat,
          lng: residence.lng,
          status: residence.status,
          close_booking: residence.close_booking,
          seo_data_title: residence.seo_data.title,
          seo_data_keywords: residence.seo_data.keywords,
          seo_data_description: residence.seo_data.description,
          seo_data_schema: residence.seo_data.schema,
          youtube_embed: residence.youtube_embed,
          aparat_embed: residence.aparat_embed,
          close_booking: residence.close_booking,
          status: residence.status,
          show_index_page: residence.show_index_page,
          commission_price: residence.commission_price,
          score: residence.score,
          show_order_number: residence.show_order_number,
          images: residence.images,
        };


        // (this.$refs.residenceInput.regulationsItemArray =
        //   residence.regulations),
        // (this.$refs.residenceInput.packagesItemArray = residence.packages),
        this.$refs.residenceInput.categories =
            this.categories;



        var autoimmune_packages = [];
        this.autoimmunePackages.forEach(
            (initialPackage, index) => {
              autoimmune_packages.push(initialPackage);
              residence.autoimmune_packages.forEach((savePackage) => {
                if (initialPackage.id == savePackage.id) {
                  autoimmune_packages[index] = savePackage;
                }
              });
            }
        );



        var packagesData = [];
        this.packages.forEach(
            (initialPackage, index) => {
              packagesData.push(initialPackage);
              residence.packages.forEach((savePackage) => {
                if (initialPackage.id == savePackage.residence_package.id) {
                  packagesData[index] = savePackage;
                }
              });
            }
        );

        var regulationsData = [];
        // console.log(
        //   "this.$store.state.app.regulations_state",
        //   this.$store.state.app.regulations_state
        // );
        this.regulations.forEach(
            (initialRegulation, index) => {
              regulationsData.push(initialRegulation);
              residence.regulations.forEach((saveRegulation) => {
                if (
                    initialRegulation.id == saveRegulation.residence_regulation.id
                ) {
                  regulationsData[index] = saveRegulation;
                }
              });
            }
        );



        var health_itemsItemArray = [];
        // console.log(
        //   "this.$store.state.app.regulations_state",
        //   this.$store.state.app.regulations_state
        // );
        this.health_items.forEach(
            (initial_health_Item, index) => {
              health_itemsItemArray.push(initial_health_Item);
              residence.health_items.forEach((saveinitial_health_Item) => {
                if (
                    initial_health_Item.id == saveinitial_health_Item.id
                ) {
                  health_itemsItemArray[index] = saveinitial_health_Item;
                }
              });
            }
        );

        this.$refs.residenceInput.helperPackage = [...residence.packages];
        this.$refs.residenceInput.helperRegulation = [...residence.regulations];
        this.$refs.residenceInput.helperRules = [...residence.rules];

        // console.log(" this.$store.state.app.packages_state", packagesData);
        this.$refs.residenceInput.packagesItemArray = packagesData;
        this.$refs.residenceInput.regulationsItemArray = regulationsData,
            this.$refs.residenceInput.autoimmunePackagesItemArray =
                autoimmune_packages;
        console.log(" autoimmune_packages", autoimmune_packages);
        // console.log(" this.$store.state.app.packages_state", packagesData);
        this.$refs.residenceInput.rules = this.rules;
        this.$refs.residenceInput.health_itemsItemArray =
            health_itemsItemArray;
        console.log(" health_itemsItemArray", health_itemsItemArray);

        if (Object.keys(residence.special_services).length > 1) {
          this.$refs.residenceInput.specialServices =
              Object.keys(residence.special_services).length - 1;
        } else {
          this.$refs.residenceInput.specialServices = 0;
        }

        this.$refs.residenceInput.bedrooms = residence.rooms.sumOfRooms;

        this.$refs.residenceInput.getCityByProvinceId(residence.state.id);
        this.$refs.residenceInput.searchLatLng();
        this.$refs.residenceInput.residenceIndex = index;

        this.residenceIndex = index;
        this.editMode = true;
        this.$refs.residenceInput.dates = null;
        this.$refs.residenceInput.editMode = this.editMode;
      } else {
        this.$refs.residenceInput.$refs.formWizard.reset ()

        this.$refs.residenceInput.residenceInput = {
          id: "",
          name: "",
          slug: "",
          host_id: "",
          category_id: "",
          similar_unit: "",
          size_foundation: "",
          size_land: "",
          floor: "",
          bathroom: "",
          delivery_time: "",
          discharge_time: "",
          residence_area: "",
          rental_method: "",
          description: "",
          rooms: { 0: { single: 0, double: 0, kaf_khab: 0, description: "" } },
          rules:[],
          // regulations:{},
          state_id: "",
          city_id: "",
          autoimmune_packages: {},
          health_items: {},
          // packages: {},

          prices: {
            priceType: "pricePerNight",
            normal_dayes: "",
            weekend_days: "",
            peack_days: "",
            extra_guest: "",
            pricePerBaby: {
              name: "هزینه به ازای هر نوزاد",
              hasPrice: false,
              price: "",
            },
            pricePerAnimal: {
              name: "هزینه حیوانات",
              hasPrice: false,
              price: "",
            },
            minNight: 1,
            standardCapacity: 1,
            maxCapacity: 0,
            extraBed: false,
            images: [],
          },
          special_services: { 0: null },
          lat: "",
          lng: "",
          status: "",
          close_booking: "",
          seo_data_title: "",
          seo_data_keywords: "",
          seo_data_description: "",
          seo_data_schema: "",
          youtube_embed: "",
          aparat_embed: "",
          commission_price: "",
          score: "",
          status: "active",
          statusclose_booking: "active",
        };


        this.$refs.residenceInput.categories =
            this.categories;

        (this.$refs.residenceInput.autoimmunePackagesItemArray =
            this.autoimmunePackages),
            (this.$refs.residenceInput.regulationsItemArray =
                this.regulations),
            (this.$refs.residenceInput.packagesItemArray =
                this.packages),
            (this.$refs.residenceInput.health_itemsItemArray =
                this.health_items),

            (this.$refs.residenceInput.rules = this.rules);
        (this.$refs.residenceInput.specialServices = 0);
        // console.log(
        //   "this.autoimmune_packages_state",
        //   this.$store.state.app.autoimmune_packages_state
        // );
        // console.log(
        //   "this.regulations_state",
        //   this.$store.state.app.regulations_state
        // );
        // console.log(
        //   "this.packages_state",
        //   this.$store.state.app.packages_state
        // );
        // console.log(
        //   "this.health_items_state",
        //   this.$store.state.app.health_items_state
        // );
        // console.log("this.rules_state", this.$store.state.app.rules_state);
        this.$refs.residenceInput.residenceIndex = 0;

        this.editMode = false;
        this.$refs.residenceInput.editMode = this.editMode;
      }
      // this.$refs.residenceInput.dates=this.getDatesFromStartTo6Month()
      this.formModal = true;
    },
    submitForm() {
      if (this.submitLoading) return;
      let errorMessage = null;
      if (!errorMessage && !this.residenceInput.building_id) {
        errorMessage = "لطفا یک کاربر را انتخاب کنید";
      }
      if (!errorMessage && !this.residenceInput.name) {
        errorMessage = "لطفا رمز را وارد کنید";
      }

      if (!errorMessage && !this.residenceInput.description) {
        errorMessage = "لطفا توضیحات را وارد کنید";
      }

      if (!errorMessage && !this.residenceInput.body) {
        errorMessage = "لطفا متن را وارد کنید";
      }

      if (!errorMessage && !this.residenceInput.count) {
        errorMessage = "لطفا تعداد را وارد کنید";
      }

      if (!errorMessage && !this.residenceInput.bedrooms) {
        errorMessage = "لطفا تعداد اتاق خواب را وارد کنید";
      }

      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.submitLoading = true;

      const requestData = {
        method: this.editMode ? "patch" : "post",
        url: this.editMode
            ? `residences/${this.residenceInput.id}`
            : `residences`,
        data: this.residenceInput,
      };

      this.$http(requestData)
          .then((response) => {
            let message = "اقامتگاه با موفقیت اضافه شد";
            if (this.editMode) message = "اقامتگاه با موفقیت ویرایش شد";
            this.$bvToast.toast(message, {
              title: this.$t("Done"),
              variant: "success",
              solid: true,
            });
            if (!this.editMode && this.buildings && this.buildings.length > 0) {
              this.$refs.residencesPaginationComponent.clear();
              this.$refs.residencesSearchComponent.clear();
              this.$refs.residencesTableHeadComponent.clear();
              this.page = 1;
              this.searchInput = "";
              this.sortBy = "";
              this.sortDir = "";
            }

            this.submitLoading = false;
            this.formModal = false;
            this.getResidences();
          })
          .catch((err) => {
            let message = "خطا در ایجاد اقامتگاه";
            if (this.editMode) message = "خطا در ویرایش اقامتگاه";
            if (err.response && err.response.data && err.response.data.message)
              message = err.response.data.message;

            this.$bvToast.toast(message, {
              title: this.$t("Error"),
              variant: "danger",
            });
            this.submitLoading = false;
            console.log(err);
          });
    },
    getBuildings(search, loading) {
      if (typeof this.buildingsCancelToken != typeof undefined) {
        this.buildingsCancelToken.cancel(
            "Operation canceled due to new request."
        );
      }
      //Save the cancel token for the current request
      this.buildingsCancelToken = this.$http.CancelToken.source();

      let data = {
        search: search,
        per_page: 10,
        page: 1,
      };

      const requestData = {
        method: "get",
        url: "buildings",
        params: data,
        cancelToken: this.buildingsCancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data.data;
            this.buildings = data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.tooltip-inner{
  font-family: "Lato", Helvetica, "Roboto", Arial, sans-serif !important;
}
</style>
